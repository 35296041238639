<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <form class="forms-sample" @submit.stop.prevent="onSubmit" autocomplete="off">
            <div class="row">
                <div class="col-md-12 d-flex align-items-stretch grid-margin">
                    <div class="row flex-grow">
                        <div class="col-12 grid-margin">
                            <div class="card">
                                <div class="card-header">
                                    <h4 class="card-title">{{ $t('Sightings: Survey Specific') }}</h4>
                                </div>
                                <div class="card-body new-card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('Survey Name*')" label-for="project_name">
                                                <b-form-select 
                                                v-model="selectedProjectId" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null" 
                                                aria-describedby="input-1-live-feedback" 
                                                :options="projectOptions" 
                                                value-field="id" 
                                                text-field="name"
                                                @change="onProjectChange">
                                                <b-form-select-option v-if="projectOptions.length === 0" :disabled="true">
                                                    {{ $t('No Project Found') }}
                                                </b-form-select-option>
                                                </b-form-select>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-4">
                                            <b-form-group :label="$t('survey-style*')" label-for="survey_style_name">
                                                <b-form-input
                                                type="text"
                                                id="survey_style_name"
                                                name="survey_style_name"
                                                :placeholder="$t('survey-style')"
                                                v-model="form.survey_style_name" 
                                                readonly>
                                                </b-form-input>
                                            </b-form-group>
                                        </div>
                                         <!-- <div class="col-md-4">
                                            <b-form-group :label="$t('project-id*')" label-for="project_id">
                                            <b-form-input
                                                type="text"
                                                id="project_id"
                                                name="project_id"
                                                autocomplete="new-password"
                                                :placeholder="$t('project-id')"
                                                v-model="form.project_id" 
                                                :state="$v.form.project_id.$dirty ? !$v.form.project_id.$error : null"
                                                readonly
                                                >
                                            </b-form-input>
                                            </b-form-group>
                                        </div>-->
                                    </div>
                                    <label for="Cues">{{ $t('Use') }}</label>
                                    <div class="row project-checkbox">
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_cue_usable">
                                                        <label for="Cues">{{ $t('cue') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <b-form-group label-for="Cues">
                                                        <b-form-select 
                                                        v-model="form.cue" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="Cues" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="Cues.length === 0" :disabled="true">
                                                            {{ $t('No Cue Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('Cues')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Offshore Survey (General)' || form.is_NARW === 1">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_method_usable">
                                                        <label for="methods">{{ $t('method') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="methods">
                                                        <b-form-select 
                                                        v-model="form.method" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="methods" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="methods.length === 0" :disabled="true">
                                                            {{ $t('No Method Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('methods')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_calibration_school_usable">
                                                        <label for="calibrationSchool">{{ $t('calibration') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="calibrationSchool">
                                                        <b-form-select 
                                                        v-model="form.calibration_school" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="calibrationSchool" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="calibrationSchool.length === 0" :disabled="true">
                                                            {{ $t('No Calibration School Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('calibrationSchool')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey' || form.is_NARW === 1">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_aerial_photos_usable">
                                                        <label for="aerialPhotos">{{ $t('Aerial Photos') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="aerialPhotos">
                                                        <b-form-select 
                                                        v-model="form.aerial_photos" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="aerialPhotos" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="aerialPhotos.length === 0" :disabled="true">
                                                            {{ $t('No Aerial Photos Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('aerialPhotos')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_resighting_usable">
                                                        <label for="Resightings">{{ $t('Resighting') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="Resightings">
                                                        <b-form-select 
                                                        v-model="form.resighting" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="Resightings" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="Resightings.length === 0" :disabled="true">
                                                            {{ $t('No Resighting Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('Resightings')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey' || form.is_NARW === 1">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_id_reliability_usable">
                                                        <label for="IdReliabilities">{{ $t('ID Reliability') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="IdReliabilities">
                                                        <b-form-select 
                                                        v-model="form.id_reliability" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="IdReliabilities" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="IdReliabilities.length === 0" :disabled="true">
                                                            {{ $t('No ID Reliability Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('IdReliabilities')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_birds_present_usable">
                                                        <label for="birdsPresent">{{ $t('Birds present') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="birdsPresent">
                                                        <b-form-select 
                                                        v-model="form.birds_present" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="birdsPresent" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="birdsPresent.length === 0" :disabled="true">
                                                            {{ $t('No Birds present Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('birdsPresent')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_biopsy_usable">
                                                        <label for="biopsies">{{ $t('Biopsy') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="biopsies">
                                                        <b-form-select 
                                                        v-model="form.biopsy" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="biopsies" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="biopsies.length === 0" :disabled="true">
                                                            {{ $t('No Biopsy Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('biopsies')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_photos_usable">
                                                        <label for="Photos">{{ $t('Photos') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="Photos">
                                                        <b-form-select 
                                                        v-model="form.photos" 
                                                        aria-describedby="input-1-live-feedback" 
                                                        :options="Photos" 
                                                        value-field="id" 
                                                        text-field="name"
                                                        >
                                                        <b-form-select-option v-if="Photos.length === 0" :disabled="true">
                                                            {{ $t('No Photos Found') }}
                                                        </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                <b-button class="btn btn-success" @click="toggleInput('Photos')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey'">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_confidence_usable">
                                                        <label for="confidence">{{ $t('confidence') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="confidence">
                                                        <b-form-select 
                                                            v-model="form.confidence" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="confidence" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="confidence.length === 0" :disabled="true">
                                                                {{ $t('no-confidence-found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('confidence')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>
                                            <!--<div class="row" v-if="form.survey_style_name === 'Aerial Survey'">
                                                <div class="col-md-3 mt-4">
                                                    <div class="form-flex-group">
                                                        <input type="checkbox" v-model="form.is_animal_heading_usable">
                                                        <label for="animal_heading">{{ $t('animal-heading') }}</label>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mt-3">
                                                    <b-form-group label-for="animal_heading">
                                                        <b-form-select 
                                                            v-model="form.animal_heading" 
                                                            aria-describedby="input-1-live-feedback" 
                                                            :options="animal_heading" 
                                                            value-field="id" 
                                                            text-field="name">
                                                            <b-form-select-option v-if="animal_heading.length === 0" :disabled="true">
                                                                {{ $t('no-animal-heading-found') }}
                                                            </b-form-select-option>
                                                        </b-form-select>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-md-3 mt-3">
                                                    <b-button class="btn btn-success" @click="toggleInput('animal_heading')">{{ $t('edit-values') }}</b-button>
                                                </div>
                                            </div>-->
                                        </div>
                                        <div class="col-lg-3">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <section>
                                                        <vue-tags-input
                                                            :tags="tags"
                                                            @tags-changed="tags = $event"
                                                            :allow-edit-tags="true"
                                                            class="tags-input"
                                                            :placeholder="$t('Enter values as tags')"
                                                            :add-only-from-autocomplete="false"
                                                        />
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <label for=""><b>{{ $t('Data Entry Fields') }}</b></label>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_bearing_azimuth_usable">
                                                            <label for="windSpeed">{{ $t('Bearing (Azimuth)') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_bearing_compass_usable">
                                                            <label for="swellHeight">{{ $t('Bearing (Compass)') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_angle_usable">
                                                            <label for="visibility">{{ $t('Angle') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_reticle_25x_usable">
                                                            <label for="visibility">{{ $t('Reticle Angle (25x)') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_reticle_7x_usable">
                                                            <label for="visibility">{{ $t('Reticle Angle (7x)') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_distance_usable">
                                                            <label for="visibility">{{ $t('Distance') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_perpendicular_distance_usable">
                                                            <label for="visibility">{{ $t('Perpendicular Distance') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_group_size_usable">
                                                            <label for="group_size">{{ $t('Group Size') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_calves_usable">
                                                            <label for="visibility">{{ $t('Calves/Pups') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_photo_frames_usable">
                                                            <label for="visibility">{{ $t('Photo Frames') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" v-if="form.survey_style_name === 'Aerial Survey' || form.is_NARW === 1">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_animal_heading_usable">
                                                            <label for="visibility">{{ $t('Animal Heading') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_hobbs_day_start_usable">
                                                            <label for="visibility">{{ $t('hobbs-day-start') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_hobbs_day_end_usable">
                                                            <label for="visibility">{{ $t('hobbs-day-end') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_wheels_up_usable">
                                                            <label for="visibility">{{ $t('wheels-up') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_wheels_down_usable">
                                                            <label for="visibility">{{ $t('wheels-down') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_engine_on_usable">
                                                            <label for="visibility">{{ $t('engine-on') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-12">
                                                    <div class="checkbox-radio">
                                                        <div class="checkbox-input">
                                                            <input type="checkbox" v-model="form.is_engine_off_usable">
                                                            <label for="visibility">{{ $t('engine-off') }}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6"></div>
                                        <div class="col-md-6 mt-3">
                                            <b-button type="submit" variant="success" :disabled="isDisabled" class="btn float-right btn-success">
                                                <span>{{ $t('submit-btn') }}</span>
                                            </b-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>
</template>


<script>
    import Vue from "vue";
    import API from '@/api'
    import Snotify, {SnotifyPosition} from 'vue-snotify'
    import {validationMixin} from "vuelidate";
    import {required, minLength, survey_style, requiredIf, sameAs} from "vuelidate/lib/validators";
    import moment from 'moment';
    import i18n from '@/i18n';
    import VueTagsInput from '@johmun/vue-tags-input';
    const options = {
        toast: {
            position: SnotifyPosition.rightTop,
        }
    }
    Vue.use(Snotify, options)
    export default {
        name: 'Users',
        mixins: [validationMixin],
        components: {
            VueTagsInput,
        },
        data() {
            return {
                form: {
                    id: '',
                    project_name: '',
                    project_id: '',
                    survey_style_id: '',
                    survey_style_name: '',
                    cue:'',
                    method:'',
                    calibration_school:'',
                    aerial_photos: '',
                    resighting: '',
                    id_reliability: '',
                    sighting_effort:'',
                    birds_present: '',
                    biopsy: '',
                    photos: '',
                    confidence: '',
                    animal_heading: '',
                    is_confidence_usable: false,
                    is_animal_heading_usable: false,
                    is_cue_usable: false,
                    is_method_usable: false,
                    is_calibration_school_usable: false,
                    is_aerial_photos_usable: false,
                    is_resighting_usable: false,
                    is_id_reliability_usable: false,
                    is_bearing_azimuth_usable: false,
                    is_bearing_compass_usable: false,
                    is_angle_usable: false,
                    is_reticle_25x_usable: false,
                    is_reticle_7x_usable: false,
                    is_distance_usable: false,
                    is_perpendicular_distance_usable: false,
                    is_group_size_usable: false,
                    is_calves_usable: false,
                    is_birds_present_usable: false,
                    is_biopsy_usable: false,
                    is_photos_usable: false,
                    is_photo_frames_usable: false,
                    is_hobbs_day_start_usable: false,
                    is_hobbs_day_end_usable: false,
                    is_wheels_up_usable: false,
                    is_wheels_down_usable: false,
                    is_engine_on_usable: false,
                    is_engine_off_usable: false,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    is_NARW: null,
                },
                isDisabled: false,
                isLoading: false,
                rows: [],
                rowsForBulkEdit: [],
                totalRecords: 0,
                orders: [],
                projectOptions: [],
                Cues: [],
                methods: [],
                calibrationSchool: [],
                aerialPhotos: [],
                Resightings: [],
                IdReliabilities: [],
                SightingEfforts: [],
                birdsPresent: [],
                biopsies: [],
                Photos: [],
                confidence: [],
                animal_heading: [],
                selectedProjectId: null,
                showInput: false,
                inputValue: '',
                currentField: '',
                tags: [],
                
            };
        },

        validations: {
            form: {
                id: {},
                project_name: {
                    required,
                }, 
                project_id: {
                    required,
                },
                survey_style_id: {
                    required,
                },
            },
        },
        mounted() {
            this.form.id = this.$route.params.id;
            if (this.form.id && this.form.id !== 'undefined') {
                this.readonly = true;
                this.getSpotter();
            } else {
                this.readonly = false;
            }
            this.isLoading = true;
            this.getNames();
        },
        methods: {
            toggleInput(field) {
                if (this.currentField === field && this.tags.length > 0) {
                    return; // Avoid overwriting when the same field is re-selected
                }

                this.currentField = field;

                // Safely map data to tags, ensuring field exists
                const fieldData = this[field] || [];
                this.tags = fieldData.map(item => ({ text: item.name || item })); // Handle undefined `name`
            },
            onSubmit() {
                if (!this.form.project_id) {
                    this.isDisabled = true;
                    this.$snotify.warning('Please fill in the project name.', {
                        timeout: 2000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    return;
                }
                let data = {
                    project_id: this.selectedProjectId,
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                    is_cue_usable : this.form.is_cue_usable,
                    is_method_usable : (this.form.survey_style_name === 'Offshore Survey (General)') 
                        ? this.form.is_method_usable 
                        : false,
                    is_calibration_school_usable : this.form.is_calibration_school_usable,
                    is_aerial_photos_usable : this.form.is_aerial_photos_usable,
                    is_resighting_usable : this.form.is_resighting_usable,
                    is_transect_width_usable : this.form.is_transect_width_usable,
                    is_id_reliability_usable: 
                    (this.form.survey_style_name === 'NARW Survey' || this.form.survey_style_name === 'Aerial Survey') 
                        ? this.form.is_id_reliability_usable 
                        : false,
                    is_bearing_azimuth_usable : this.form.is_bearing_azimuth_usable,
                    is_bearing_compass_usable : this.form.is_bearing_compass_usable,
                    is_angle_usable : this.form.is_angle_usable,
                    is_reticle_25x_usable: this.form.is_reticle_25x_usable,
                    is_reticle_7x_usable: this.form.is_reticle_7x_usable,
                    is_distance_usable: this.form.is_distance_usable,
                    is_perpendicular_distance_usable: this.form.is_perpendicular_distance_usable,
                    is_group_size_usable : this.form.is_group_size_usable,
                    is_calves_usable : this.form.is_calves_usable,
                    is_birds_present_usable : this.form.is_birds_present_usable,
                    is_biopsy_usable : this.form.is_biopsy_usable,
                    is_photos_usable : this.form.is_photos_usable,
                    is_photo_frames_usable : this.form.is_photo_frames_usable,
                    is_confidence_usable : (this.form.survey_style_name === 'Aerial Survey') 
                        ? this.form.is_confidence_usable 
                        : false,
                    is_animal_heading_usable : (this.form.survey_style_name === 'Aerial Survey') 
                        ? this.form.is_animal_heading_usable 
                        : false,
                    is_hobbs_day_start_usable : this.form.is_hobbs_day_start_usable,
                    is_hobbs_day_end_usable : this.form.is_hobbs_day_end_usable,
                    is_wheels_up_usable : this.form.is_wheels_up_usable,
                    is_wheels_down_usable : this.form.is_wheels_down_usable,
                    is_engine_on_usable : this.form.is_engine_on_usable,
                    is_engine_off_usable : this.form.is_engine_off_usable,
                };
                if (this.currentField === 'Cues') {
                    data.cue = this.inputValue;
                    console.log('Cues', data.Cues);
                } 
                else if (this.currentField === 'methods') {
                    data.method = this.inputValue;
                } 
                else if (this.currentField === 'calibrationSchool') {
                    data.calibration_school = this.inputValue;
                }
                else if (this.currentField === 'aerialPhotos') {
                    data.aerial_photos = this.inputValue;
                }
                else if (this.currentField === 'Resightings') {
                    data.resighting = this.inputValue;
                }
                else if (this.currentField === 'transectWidths') {
                    data.transect_width = this.inputValue;
                }
                else if (this.currentField === 'IdReliabilities') {
                    data.id_reliability = this.inputValue;
                }
                else if (this.currentField === 'groupSizes') {
                    data.group_size = this.inputValue;
                }
                else if (this.currentField === 'Calves') {
                    data.calves = this.inputValue;
                }
                else if (this.currentField === 'SightingEfforts') {
                    data.sighting_effort = this.inputValue;
                }
                else if (this.currentField === 'birdsPresent') {
                    data.birds_present = this.inputValue;
                }
                else if (this.currentField === 'biopsies') {
                    data.biopsy = this.inputValue;
                }
                else if (this.currentField === 'Photos') {
                    data.photos = this.inputValue;
                }
                else if (this.currentField === 'photoFrame') {
                    data.photo_frames = this.inputValue;
                }
                else if (this.currentField === 'confidence') {
                    data.confidence = this.inputValue;
                }
                else if (this.currentField === 'animal_heading') {
                    data.animal_heading = this.inputValue;
                }
                API.addEditSightingInfo(
                    data,
                    (data) => {
                        this.isDisabled = false;
                        this.$v.form.$reset();
                        this.$snotify.success(data.message, {
                            timeout: 2000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        this.inputValue = '';
                        this.currentField = '';
                        this.tags =[];
                        this.getSightingInfo(this.selectedProjectId);
                    },
                    // (err) => {
                    //     this.isDisabled = false;
                    //     this.$snotify.error(err, {
                    //         timeout: 2000,
                    //         showProgressBar: false,
                    //         closeOnClick: true,
                    //         pauseOnHover: true,
                    //     });
                    // }
                );
            },
            getNames() {
                const data = {
                    client_id: JSON.parse(localStorage.getItem('client_id')),
                };
                API.getProjectName(
                    data,
                    response => {
                    this.projectOptions = response.data.map(project => ({
                        id: project.id,
                        name: project.project_name
                    }));
                        if (this.projectOptions.length > 0) {
                            this.selectedProjectId = this.projectOptions[0].id;
                            this.getProjectStyleData(this.selectedProjectId);
                            this.getSightingInfo(this.selectedProjectId);
                            this.toggleInput();
                        }
                    },
                    err => {
                    console.error('Error fetching project names:', err);
                    }
                );
            },
            getProjectStyleData(projectId) {
                API.getProjectSetup(
                    { projectId },
                    response => {
                        const project = response.data;
                        this.form.project_id = project.project_id;
                        this.form.survey_style_id = project.survey_style_id;
                        this.form.is_NARW = project.is_NARW;
                        this.form.survey_style_name = project.survey_style ? project.survey_style.name : '';
                    },
                    err => {
                    console.error('Error fetching project details:', err);
                    }
                );
            },
            getSightingInfo(projectId) {
                API.getSightingInfo({ projectId }, response => {
                    const projects = response.data;
                    this.Cues = [];
                    this.methods = [];
                    this.calibrationSchool = [];
                    this.aerialPhotos = [];
                    this.Resightings = [];
                    this.transectWidths = [];
                    this.IdReliabilities = [];
                    this.SightingEfforts = [];
                    this.birdsPresent = [];
                    this.biopsies = [];
                    this.Photos = [];
                    this.confidence = [];
                    this.animal_heading = [];
                    this.form.is_hobbs_day_start_usable = false;
                    this.form.is_hobbs_day_end_usable = false;
                    this.form.is_wheels_up_usable = false;
                    this.form.is_wheels_down_usable = false;
                    this.form.is_engine_on_usable = false;
                    this.form.is_engine_off_usable = false;

                    if (projects.length > 0) {
                        projects.forEach(project => {
                            const { key, values, is_usable } = project;
                            const valueArray = values ? values.split(',').map(val => val.trim()) : [];

                            switch (key) {
                                case 'cue':
                                    this.Cues = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_cue_usable = !!is_usable;
                                    break;
                                case 'method':
                                    this.methods = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_method_usable = !!is_usable;
                                    break;
                                case 'calibration_school':
                                    this.calibrationSchool = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_calibration_school_usable = !!is_usable;
                                    break;
                                case 'aerial_photos':
                                    this.aerialPhotos = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_aerial_photos_usable = !!is_usable;
                                    break;
                                case 'resighting':
                                    this.Resightings = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_resighting_usable = !!is_usable;
                                    break;
                                case 'transect_width':
                                    this.transectWidths = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_transect_width_usable = !!is_usable;
                                    break;
                                case 'id_reliability':
                                    this.IdReliabilities = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_id_reliability_usable = !!is_usable;
                                    break;
                                case 'bearing_azimuth':
                                    this.form.is_bearing_azimuth_usable = !!is_usable;
                                    break;
                                case 'bearing_compass':
                                    this.form.is_bearing_compass_usable = !!is_usable;
                                    break;
                                case 'angle':
                                    this.form.is_angle_usable = !!is_usable;
                                    break;
                                case 'reticle_25x':
                                    this.form.is_reticle_25x_usable = !!is_usable;
                                    break;
                                case 'reticle_7x':
                                    this.form.is_reticle_7x_usable = !!is_usable;
                                    break;
                                case 'distance':
                                    this.form.is_distance_usable = !!is_usable;
                                    break;
                                case 'perpendicular_distance':
                                    this.form.is_perpendicular_distance_usable = !!is_usable;
                                    break;
                                case 'group_size':
                                    this.groupSizes = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_group_size_usable = !!is_usable;
                                    break;
                                case 'calves':
                                    this.Calves = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_calves_usable = !!is_usable;
                                    break;
                                case 'birds_present':
                                    this.birdsPresent = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_birds_present_usable = !!is_usable;
                                    break;
                                case 'biopsy':
                                    this.biopsies = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_biopsy_usable = !!is_usable;
                                    break;
                                case 'photos':
                                    this.Photos = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_photos_usable = !!is_usable;
                                    break;
                                case 'photo_frames':
                                    this.photoFrame = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_photo_frames_usable = !!is_usable;
                                    break;
                                case 'confidence':
                                    this.confidence = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_confidence_usable = !!is_usable;
                                    break;
                                case 'animal_heading':
                                    this.animal_heading = valueArray.map(val => ({ id: project.id, name: val }));
                                    this.form.is_animal_heading_usable = !!is_usable;
                                    break;
                                case 'hobbs_day_start':
                                    this.form.is_hobbs_day_start_usable = !!is_usable;
                                    break;
                                case 'hobbs_day_end':
                                    this.form.is_hobbs_day_end_usable = !!is_usable;
                                    break;
                                case 'wheels_up':
                                    this.form.is_wheels_up_usable = !!is_usable;
                                    break;
                                case 'wheels_down':
                                    this.form.is_wheels_down_usable = !!is_usable;
                                    break;
                                case 'engine_on':
                                    this.form.is_engine_on_usable = !!is_usable;
                                    break;
                                case 'engine_off':
                                    this.form.is_engine_off_usable = !!is_usable;
                                    break;
                            }
                        });
                    }
                    else
                    {
                        this.form.is_cue_usable = false;
                        this.form.is_method_usable = false;
                        this.form.is_calibration_school_usable = false;
                        this.form.is_aerial_photos_usable = false;
                        this.form.is_resighting_usable = false;
                        this.form.is_id_reliability_usable = false;
                        this.form.is_bearing_azimuth_usable = false;
                        this.form.is_bearing_compass_usable = false;
                        this.form.is_angle_usable = false;
                        this.form.is_reticle_25x_usable = false;
                        this.form.is_reticle_7x_usable = false;
                        this.form.is_distance_usable = false;
                        this.form.is_perpendicular_distance_usable = false;
                        this.form.is_group_size_usable = false;
                        this.form.is_calves_usable = false;
                        this.form.is_birds_present_usable = false;
                        this.form.is_biopsy_usable = false;
                        this.form.is_photos_usable = false;
                        this.form.is_photo_frames_usable = false;
                        this.form.is_confidence_usable = false;
                        this.form.is_animal_heading_usable = false;
                        this.form.is_hobbs_day_start_usable = false;
                        this.form.is_hobbs_day_end_usable = false;
                        this.form.is_wheels_up_usable = false;
                        this.form.is_wheels_down_usable = false;
                        this.form.is_engine_on_usable = false;
                        this.form.is_engine_off_usable = false;

                    }
                }, err => {
                    console.error('Error fetching project weather details:', err);
                });
            },
            onProjectChange(selectedProjectId) {
                if (selectedProjectId) {
                    this.getProjectStyleData(selectedProjectId);
                    this.getSightingInfo(selectedProjectId);
                    this.toggleInput();
                }
            },
        }, 
        watch: {
            tags: {
                handler(newTags) {
                    const formattedTags = (newTags || []).map(tag => tag.text || '');
                    if (this.currentField) {
                        this[this.currentField] = formattedTags;
                    }
                    this.inputValue = formattedTags.join(',');
                },
                deep: true,
            },
        },
        computed: {
            CuesName: {
                get() {
                    return this.Cues.map(code => code.name).join(', ');
                },
                set(newValue) {
                    const Cues = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.Cues.find(code => code.name === name.trim()).id };
                    });
                    this.form.cue = Cues;
                }
            },
            methodNames: {
                get() {
                    return this.methods.map(method => method.name).join(', ');
                },
                set(newValue) {
                    const methods = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.methods.find(method => method.name === name.trim()).id };
                    });
                    this.form.method = methods;
                }
            },
            calibrationSchoolNames: {
                get() {
                    return this.calibrationSchool.map(number => number.name).join(', ');
                },
                set(newValue) {
                    const calibrationSchool = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.calibrationSchool.find(number => number.name === name.trim()).id };
                    });
                    this.form.number = calibrationSchool;
                }
            },
            aerialPhotosNames: {
                get() {
                    return this.aerialPhotos.map(cloud => cloud.name).join(', ');
                },
                set(newValue) {
                    const aerialPhotos = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.aerialPhotos.find(cloud => cloud.name === name.trim()).id };
                    });
                    this.form.aerial_photos = aerialPhotos;
                }
            },
            ResightingNames: {
                get() {
                    return this.Resightings.map(direction => direction.name).join(', ');
                },
                set(newValue) {
                    const Resightings = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.Resightings.find(direction => direction.name === name.trim()).id };
                    });
                    this.form.resighting = Resightings;
                }
            },
            IdReliabilitiesNames: {
                get() {
                    return this.IdReliabilities.map(w => w.name).join(', ');
                },
                set(newValue) {
                    const IdReliabilities = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.IdReliabilities.find(w => w.name === name.trim()).id };
                    });
                    this.form.id_reliability = IdReliabilities;
                }
            },
            SightingEffortsNames: {
                get() {
                    return this.SightingEfforts.map(t => t.name).join(', ');
                },
                set(newValue) {
                    const SightingEfforts = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.SightingEfforts.find(t => t.name === name.trim()).id };
                    });
                    this.form.sighting_effort = SightingEfforts;
                }
            },
            birdsPresentNames: {
                get() {
                    return this.birdsPresent.map(t => t.name).join(', ');
                },
                set(newValue) {
                    const birdsPresent = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.birdsPresent.find(t => t.name === name.trim()).id };
                    });
                    this.form.birds_present = birdsPresent;
                }
            },
            biopsiesName: {
                get() {
                    return this.biopsies.map(d => d.name).join(', ');
                },
                set(newValue) {
                    const biopsies = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.biopsies.find(d => d.name === name.trim()).id };
                    });
                    this.form.biopsy = biopsies;
                }
            },
            PhotosNames: {
                get() {
                    return this.Photos.map(quality => quality.name).join(', ');
                },
                set(newValue) {
                    const Photos = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.Photos.find(quality => quality.name === name.trim()).id };
                    });
                    this.form.photos = Photos;
                }
            },
            confidenceNames: {
                get() {
                    return this.confidence.map(c => c.name).join(', ');
                },
                set(newValue) {
                    const confidence = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.confidence.find(c => c.name === name.trim()).id };
                    });
                    this.form.confidence = confidence;
                }
            },
            animalHeadingNames: {
                get() {
                    return this.animal_heading.map(c => c.name).join(', ');
                },
                set(newValue) {
                    const animal_heading = newValue.split(',').map(name => {
                        return { name: name.trim(), id: this.animal_heading.find(c => c.name === name.trim()).id };
                    });
                    this.form.animal_heading = animal_heading;
                }
            },

        },
    }
</script>

<style>
    .btn-duplicate {
        width: 80px !important;
        padding-left: 10px !important;
        background: #5D9E12;
        border-color: #5D9E12;
    }
    .checkbox-radio {
        display: flex;
        gap: 35px;
    }
    .checkbox-radio label {
        padding-left: 7px;
    }
    .card-body.new-card-body select {
        border: 1px solid #000;
        border-radius: 0;
        height: 46px;
    }
    .project-checkbox label {
        padding-left: 15px;
    }
    .mandatoryCSS {
        background: lightblue;
        border-block: initial;
        border: 1px solid;
        margin-left: 10px;
        max-width: 30%;
        padding-top: 15px;
    }
    .optionalCSS {
        background: lightgreen;
        border-block: initial;
        border: 1px solid;
        margin-left: 10px;
        max-width: 30%;
        padding-top: 15px;
    }
    .optionalCSS h4 {
        padding-left: 27px;
        margin: 0;
    }
    .checkbox-input.optionalCSS-field {
        display: flex;
        align-items: center;
    }
    .checkbox-input.optionalCSS-field label span {
        display: block;
    }
    .project-checkbox textarea {
        border: 1px solid #000 !important;
        height: 250px;
        outline: none;
        box-shadow: none;
        border-radius: 0;
    }
    .form-flex-group {
        display: flex;
        align-items: baseline;
    }
    @media (max-width: 375px) {
        .row .card .new-card-body {
            padding: 1.25rem 1.437rem !important;
        }
    }
</style>
